import { getAllTagsWithParents, getTagData } from "api/tags";
import { getAllExpressions, getExpressionData } from "api/expression";
import { useEffect, useState, useCallback } from "react";
import UnityWidgetContainer from "components/UnityWidgetContainer/UnityWidgetContainer";
import { useLocation } from "react-router-dom";
import useData from "hooks/useData";

const Unity = () => {
  const [tagsMessage, setTagsMessage] = useState<any>();
  const [expressionsMessage, setExpressionsMessage] = useState<any>();
  const [tagsIds, setTagsIds] = useState();
  const [expressionIds, setExpressionsIds] = useState();
  const [gaugeData, setGaugeData] = useState([]);
  const [isGauge, setIsGauge] = useState(false);
  const location = useLocation();
  const isScaled = false;
  const { pastDate } = useData();
  const headerSize = isScaled ? 80 : 64;

  if (window.vuplex) {
    addMessageListener();
  } else {
    window.addEventListener('vuplexready', addMessageListener);
  }

  function addMessageListener() {
    window.vuplex.addEventListener('message', async function (event: any) {
      const json = event.data;
      const parsedData = JSON.parse(json);
      const { methodName, message } = parsedData;
      if (methodName === 'UnityTagsRequest') {
        if (message.length > 0) {
          const ids = JSON.parse(message);
          setTagsIds(ids);
        }
      }

      if (methodName === 'UnityExpressionsRequest') {
        if (message.length > 0) {
          const ids = JSON.parse(message);
          setExpressionsIds(ids);
        }
      }
    });
  }

  const fetchData = async (apiCall: any, setMessage: any) => {
    try {
      const response: any = await apiCall();
      const { data } = response;
      const dataToString = JSON.stringify(data);
      setMessage(dataToString);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  const useUnityDataEffect = (selectedData: any, apiCall: any, method: any) => {
    useEffect(() => {
      if (selectedData) {
        const fetchData = async () => {
          const response = await apiCall(selectedData, pastDate);
          const processedData = response.data;
          const message = JSON.stringify({ object: "DataManager", type: "DataManager", method, arguments: [JSON.stringify(processedData)] });
          window.vuplex.postMessage(message);
        };

        fetchData();

        const intervalId = setInterval(fetchData, 15000);

        return () => {
          clearInterval(intervalId);
        };
      }
    }, [selectedData, method, pastDate]);
  };

  useEffect(() => {
    if (window.vuplex) {
      if (location.pathname === '/unity') {
        sendCanvasVisibility(1);
        sendHeaderHeight(headerSize);
      }
    }
    return () => {
      if (window.vuplex) {
        sendCanvasVisibility(0);
      }
    };
  }, [window.vuplex, location.pathname, headerSize]);


  useUnityDataEffect(tagsIds, getTagData, "SetTagsData");
  useUnityDataEffect(expressionIds, getExpressionData, "SetExpressionsData");

  useEffect(() => {
    const fetchData = async () => {
      const response = await getExpressionData([24], pastDate);
      if (response.status === 200) {
        setGaugeData(response.data);
        setIsGauge(true);
      }
      if (response.status !== 200) {
        setIsGauge(false);
      }
    };
    fetchData();
    const intervalId = setInterval(fetchData, 15000);
    return () => {
      clearInterval(intervalId);
    };
  }, [window.vuplex, pastDate]);

  useEffect(() => {
    if (window.vuplex) {
      fetchData(getAllTagsWithParents, setTagsMessage);
      fetchData(getAllExpressions, setExpressionsMessage);
    }
  }, [window.vuplex]);

  useEffect(() => {
    if (tagsMessage) {
      sendTags();
    }
    if (expressionsMessage) {
      sendExpressions();
    }
  }, [tagsMessage, expressionsMessage]);

  function sendHeaderHeight(height: number) {
    window.vuplex.postMessage(JSON.stringify({ object: "CanvasVisibility", type: "CanvasVisibility", method: "SetTopPanelHeight", arguments: [height] }));
  }

  function sendCanvasVisibility(state: number) {
    window.vuplex.postMessage(JSON.stringify({ object: "CanvasVisibility", type: "CanvasVisibility", method: "SetStatus", arguments: [state] }));
  }

  function sendTags() {
    window.vuplex.postMessage(JSON.stringify({ object: "DataManager", type: "DataManager", method: "SetAllTagsMeta", arguments: [tagsMessage] }));
  }

  function sendExpressions() {
    window.vuplex.postMessage(JSON.stringify({ object: "DataManager", type: "DataManager", method: "SetAllExpressionsMeta", arguments: [expressionsMessage] }));
  }

  return (
    <>
      <div className="relative pointer-events-none">
      </div>
      {isGauge && gaugeData.length > 0 &&
        <div className="absolute top-[85px] right-0 w-max h-max">
          <UnityWidgetContainer data={gaugeData[0]} name='Total Visitors' units='People' />
        </div>
      }
    </>
  );
};

export default Unity;
