import useApp from "hooks/useApp";
import useScreen from "hooks/useScreen";
import "moment-timezone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FiClock, FiPause, FiPlay } from "react-icons/fi";
import { IoReloadOutline } from "react-icons/io5";

import useData from "hooks/useData";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import classNames from "utilities/ClassNames";

export default function DateTime() {
  const { user } = useApp();
  const { isScaled } = useScreen();
  const { pastDateHandler, setDayUnity } = useData();
  const [day, setDaySkyBox] = useState(1);
  const defaultTimeZone = "Asia/Kuwait";
  const userTimeZone = user?.ianaTimeZone || defaultTimeZone;

  const excludeStartDate = new Date(
    moment().set("year", 2022).set("month", 4).set("date", 25).format("lll")
  );
  const excludeEndDate = new Date(
    moment().set({ year: 2022, month: 11, date: 5 }).format("lll")
  );
  const formatter = "YYYY-MM-DD[T]HH:mm:ss";

  const timeZoneTime = () => {
    const localTime = new Date();
    const momentTime = moment(localTime).tz(userTimeZone);

    return new Date(momentTime.format(formatter));
  };

  const [timer, runTimer] = useState(true);
  const [selectedDate, setSelectedDate] = useState(timeZoneTime());
  const [calendar, showCalendar] = useState(false);
  const [isCurrentDate, setIsCurrentDate] = useState(true);
  const [paused, setPaused] = useState(false);

  useEffect(() => {
    if (paused) {
      pastDateHandler(true, moment(selectedDate).format(formatter));
    }
  }, [paused, pastDateHandler, selectedDate]);

  useEffect(() => {
    if (calendar) {
      runTimer(false);
    } else {
      runTimer(true);
      if (!isCurrentDate) {
        setPaused(false);
      }
    }
  }, [calendar, isCurrentDate]);

  useEffect(() => {
    const checkSaudiDayNight = (dateTime: Date) => {
      const timeSaudi = moment
        .tz(moment(dateTime).format(formatter), userTimeZone)
        .tz("Asia/Kuwait");
      const hour = timeSaudi.hours();
      if (hour > 5 && hour < 18) {
        if (!day) {
          setDaySkyBox(1);
        }
      } else if (day) {
        setDaySkyBox(0);
      }
    };

    checkSaudiDayNight(selectedDate);
  }, [selectedDate, day, userTimeZone]);

  useEffect(() => {
    const secondsTimer = setInterval(() => {
      setSelectedDate((state) => {
        let newDate;
        if (isCurrentDate) {
          newDate = timeZoneTime();
        } else {
          newDate = state;
          const x = newDate.getSeconds() + 1;
          newDate.setSeconds(x);
        }

        return new Date(newDate);
      });
    }, 1000);

    if (!timer || paused) {
      clearInterval(secondsTimer);
    }
    return () => {
      clearInterval(secondsTimer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
    // eslint-disable-next-line
  }, [timer, paused, isCurrentDate, userTimeZone]);

  useEffect(() => {
    setDayUnity(day);
  }, [day, setDayUnity]);

  /* Starts === Updating past data for every 1 minute  - if the timer running for the past date */
  useEffect(() => {
    const SyncPastDataWithTimer = setInterval(() => {
      setSelectedDate((state) => {
        pastDateHandler(true, moment(new Date(state)).format(formatter));
        return state;
      });
    }, 60 * 1000);

    if (isCurrentDate || (!isCurrentDate && paused)) {
      clearInterval(SyncPastDataWithTimer);
    }
    return () => {
      clearInterval(SyncPastDataWithTimer);
    };
    // eslint-disable-next-line
  }, [paused, isCurrentDate]);
  /* Ends === Updating past data for every 1 minute  - if the timer running for the past date */

  const clockIconHandler = () => {
    showCalendar((state) => !state);
  };

  const calendarContainer = ({ children }: any) => {
    return (
      <div className="calendar">
        <div className="sidebar-card w-full flex right-10">
          <div className="relative w-full ">{children}</div>
        </div>
      </div>
    );
  };

  const handleCalendarClose = () => { };

  const handleCalendarOpen = () => {
    showCalendar(true);
  };

  const dateTimeHandler = (date: Date) => {
    setSelectedDate(date);
    pastDateHandler(true, moment(date).format(formatter));
    setIsCurrentDate(false);
  };

  const playPauseHandler = () => {
    setPaused((state) => !state);
  };

  const refreshHandler = () => {
    setSelectedDate(timeZoneTime());
    setIsCurrentDate(true);
    setPaused(false);
    runTimer(true);
    pastDateHandler(false, null);
  };

  const disablePauseBtn = paused ? "play-pause-disable" : "";
  const disablePlayBtn = !paused ? "play-pause-disable" : "";

  return (
    <div
      className="date-time-container date-time flex justify-center items-center mr-7"
      style={{ position: "relative" }}
    >
      {calendar && (
        <div
          style={{
            position: "fixed",
            zIndex: "10",
            inset: 0,
            width: "100%",
            height: "100%",
          }}
          onClick={() => showCalendar(false)}
        />
      )}
      <div
        className="card-content flex items-center"
        style={{ position: "relative", zIndex: "20" }}
      >
        <div className="mr-3">
          {!isCurrentDate && (
            <div
              className="hover:text-green cursor-pointer pl-3"
              onClick={refreshHandler}
            >
              <IoReloadOutline size={isScaled ? 25 : 23} />
            </div>
          )}
        </div>

        <div className="mr-1">
          <div
            className={`${isCurrentDate
              ? "text-lightGray cursor-default"
              : paused
                ? "text-green cursor-pointer"
                : "text-lightGray cursor-default"
              }`}
          >
            <div onClick={playPauseHandler}>
              <FiPlay size={isScaled ? 25 : 22} />
            </div>
          </div>
        </div>
        <div className="mr-3">
          <div
            className={`${isCurrentDate
              ? "text-lightGray cursor-default"
              : !paused
                ? "text-green cursor-pointer"
                : "text-lightGray cursor-default"
              }`}
          >
            <div onClick={playPauseHandler}>
              <FiPause size={isScaled ? 25 : 22} />
            </div>
          </div>
        </div>

        <DatePicker
          selected={selectedDate}
          onInputClick={handleCalendarOpen}
          onChange={dateTimeHandler}
          showTimeSelect={true}
          timeIntervals={30}
          className={classNames(
            "w-[220px] lg:text-sm 2xl:w-[245px] 2xl:text-base mr-3",
            "border-none outline-none bg-dark cursor-pointer"
          )}
          dateFormat="MMMM d, yyyy h:mm:ss aa"
          onCalendarClose={handleCalendarClose}
          onCalendarOpen={handleCalendarOpen}
          maxDate={timeZoneTime()}
          calendarContainer={calendarContainer}
          startOpen={false}
          timeInputLabel="Time:"
          includeDateIntervals={[
            { start: new Date("May 5, 2021"), end: timeZoneTime() },
          ]}
          excludeDateIntervals={[
            { start: excludeStartDate, end: excludeEndDate },
          ]}
          open={calendar}
          showDisabledMonthNavigation
        />

        <div className="hover:text-green cursor-pointer">
          <FiClock size={isScaled ? 25 : 22} onClick={clockIconHandler} />
        </div>
      </div>
    </div>
  );
}
